/**
 * Do not edit directly
 * Generated on Wed, 29 Jan 2025 12:10:58 GMT
 */

:root {
  --duration-fast-01: 0.1s;
  --duration-medium-02: 0.2s;
  --duration-slow-03: 0.3s;
  --duration-pulse: 2s;
  --easing-pulse: cubic-bezier(0.4, 0, 0.6, 1);
  --border-radius-2: 2px;
  --border-radius-4: 4px;
  --border-radius-6: 6px;
  --border-radius-8: 8px;
  --border-radius-10: 10px;
  --border-radius-12: 12px;
  --border-radius-16: 16px;
  --border-radius-24: 24px;
  --border-radius-full: 9999px;
  --border-radius-inputs: 8px;
  --border-radius-buttons: 8px;
  --border-radius-controls: 4px;
  --border-width-1: 1px;
  --border-width-2: 2px;
  --border-width-4: 4px;
  --border-width-inputs: 2px;
  --border-width-focus: 2px;
  --border-width-controls: 2px;
  --border-width-dividers: 1px;
  --button-size-large-48: 48px;
  --button-size-medium-36: 36px;
  --button-size-small-24: 24px;
  --button-icon-size-large-48: 24px;
  --button-icon-size-medium-36: 20px;
  --button-icon-size-small-24: 16px;
  --close-button-size-large-24: 24px;
  --close-button-size-medium-20: 20px;
  --close-button-size-small-16: 16px;
  --input-width-default: 264px;
  --navigation-menu-width-default: 248px;
  --navigation-menu-width-collapsed: 72px;
  --mobile-header-height-small: 64px;
  --mobile-header-height-medium: 80px;
  --popover-max-height: 300px;
  --scrollbar-size: 8px;
  --tile-min-width-medium: 296px;
  --tile-min-width-large: 428px;
  --size-4: 4px;
  --size-8: 8px;
  --size-12: 12px;
  --size-16: 16px;
  --size-20: 20px;
  --size-24: 24px;
  --size-28: 28px;
  --size-32: 32px;
  --size-36: 36px;
  --size-48: 48px;
  --size-56: 56px;
  --size-64: 64px;
  --size-base: 4px;
  --spacing-1: 1px;
  --spacing-2: 2px;
  --spacing-4: 4px;
  --spacing-6: 6px;
  --spacing-8: 8px;
  --spacing-12: 12px;
  --spacing-16: 16px;
  --spacing-20: 20px;
  --spacing-24: 24px;
  --spacing-32: 32px;
  --spacing-40: 40px;
  --spacing-48: 48px;
  --spacing-64: 64px;
  --spacing-80: 80px;
  --spacing-96: 96px;
  --spacing-128: 128px;
  --spacing-192: 192px;
  --spacing-base: 4px;
  --shadow-small: 0px 0px 6px 0px rgba(73, 86, 112, 0.02), 0px 2px 4px 0px rgba(73, 86, 112, 0.12);
  --shadow-medium: 0px 0px 4px 0px rgba(73, 86, 112, 0.04), 0px 4px 8px 0px rgba(73, 86, 112, 0.12);
  --shadow-large: 0px 0px 4px 0px rgba(73, 86, 112, 0.04), 0px 8px 16px 0px rgba(73, 86, 112, 0.12);
  --z-index-always-below: -1;
  --z-index-base: 1;
  --z-index-old-menu: 10;
  --z-index-sticky-scrollbar: 1000;
  --z-index-sticky-tabs: 3000;
  --z-index-menu: 5000;
  --z-index-modal: 8000;
  --z-index-old-modals: 10000;
  --z-index-notifications: 9000;
  --z-index-popover: 11000;
  --z-index-tooltip: 12000;
}
