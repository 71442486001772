/**
 * Do not edit directly
 * Generated on Wed, 29 Jan 2025 12:10:58 GMT
 */

:root {
  --content-max-width: 1576px;
  --content-grid-gap: 16px;
  --content-grid-horizontal-padding: 16px;
  --content-grid-columns-count: 4;
}

@media (--breakpoint-medium-768-up) {
  :root {
    --content-max-width: 1576px;
    --content-grid-gap: 24px;
    --content-grid-horizontal-padding: 24px;
    --content-grid-columns-count: 6;
  }
}

@media (--breakpoint-large-1280-up) {
  :root {
    --content-max-width: 1576px;
    --content-grid-gap: 24px;
    --content-grid-horizontal-padding: 48px;
    --content-grid-columns-count: 8;
  }
}

@media (--breakpoint-x-large-1920-up) {
  :root {
    --content-max-width: 1576px;
    --content-grid-gap: 24px;
    --content-grid-horizontal-padding: 48px;
    --content-grid-columns-count: 8;
  }
}
